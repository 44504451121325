document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";


  // noise init one element param

  let noiseOne = document.querySelector(".js__noise"),
    noiseTwo = document.querySelector(".js__noise-two"),
    noiseThree = document.querySelector(".js__noise-three"),
    noiseFive = document.querySelector(".js__noise-five"),
    noiseForm = document.querySelector(".js__noiseForm");

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
      $(".header").addClass("box");
    } else {
      $(".header").removeClass("box");
    }
  });

  if (noiseOne) {
    setTimeout(()=> noiseMany(".js__noise"), 300)
  }
  if (noiseTwo) {
    noiseMany(".js__noise-two");
  }
  if (noiseThree) {
    noiseMany(".js__noise-three");
  }
  if (noiseFive) {
    noiseMany(".js__noise-five");
  }
  if(noiseForm){
    noiseMany(".js__noiseForm");
  }

  // menu
  let burger = $(".js__nav-toggle"),
    navWrap = $(".nav-wrap"),
    navInner = $(".nav__inner"),
    navVideo = $(".nav__background");
    navVideo.click(function () {
    burger.trigger("click");
  });
  burger.on("click", function () {
    $(this).toggleClass("js-nav-toggle--close");
    navWrap.fadeToggle("normal", function () {
      $(this).removeAttr("style");
      $(this).toggleClass("active");
    });
    navVideo.toggleClass("active");
    navInner.toggleClass("active");
    $("body").toggleClass("overflow");
  });


  const textInput = document.querySelectorAll(".js__input");

  textInput.forEach(function (item) {
    item.addEventListener("input", (event) => {
      item.parentElement.setAttribute("data-text", event.target.value);
    });
  });


  $(".js__more").click(function (e) {
    $(this).next(".js__close").slideToggle();
  });

  $(".js__to[href^='#']").click(function () {
    var target = $(this).attr("href");
    $("html, body").animate({
      scrollTop: $(target).offset().top + "px",
    });
    return false;
  });




  document.querySelectorAll('.button')
      .forEach((button) =>{
        button.innerHTML =
            `<div><span>  ${button.textContent.trim().split('').join('</span><span>')}  </span></div>`
      });
  document.querySelectorAll('.button span').forEach((item)=>{
    if (item.innerHTML === " "){
      item.classList.add('mar');
    }
  })




});
